<div style="margin: 10px;"></div>
<div *ngIf="add_new_voucher">
    <mat-form-field style="width:200px;margin-right:30px">
        <input matInput [(ngModel)]="new_voucher_value" placeholder="Unetite vrednost vaučera">
    </mat-form-field>
    <button mat-raised-button [style.background-color]="configServise.negativeColor" style="color: white" (click)="save()"><mat-icon>save</mat-icon> Sacuvaj </button>
</div>


<div class="mat-elevation-z8" style="width:500px;margin:auto;">
    <table mat-table class="full-width-table" matSort aria-label="Elements" style="width:500px">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ configServise.translationJSON.id }} </th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>
    
      <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Vrednost </th>
            <td mat-cell *matCellDef="let row">{{row.value}}</td>
      </ng-container>
    
      <ng-container matColumnDef="qr_barcode_count_u_novcaniku">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> U novčanicima </th>
            <td mat-cell *matCellDef="let row">{{row.number_of_codes.u_novcaniku}}</td>
      </ng-container>
    
      <ng-container matColumnDef="qr_barcode_count_dostupni">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dostupni </th>
            <td mat-cell *matCellDef="let row">{{row.number_of_codes.dostupni}}</td>
      </ng-container>


      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
            <button *ngIf="!add_new_voucher" mat-raised-button [style.background-color]="configServise.negativeColor" style="color: white" (click)="add()"><mat-icon>add</mat-icon> </button>
        </th>
        <td mat-cell *matCellDef="let row">
            <button *ngIf="row.number_of_codes == 0" mat-raised-button [style.background-color]="configServise.negativeColor" style="color: white" (click)="openDialog1(row.id)"> {{ configServise.translationJSON.delete }} </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator
        [length]="dataSource?.data.length"
        [pageIndex]="0"
        [pageSize]="15"
        [pageSizeOptions]="[15, 25, 50, 100, 250]">
    </mat-paginator>
</div>
